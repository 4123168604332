.navbar-style { 
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 50px rgb(167,83,198);
    border-bottom: 2px solid #faf2ff;
    z-index: 10;
    font-family: Avalors;
    color: white !important;
    position: sticky !important;
    top: 0 !important;
    cursor: url('../icons/cursor-passive.ico') 15 15, auto;
    text-align: center !important;
}

.nav-links {
    color:  #c9bba6 !important;
     -webkit-text-stroke: 1.5px black;
     font-size: 16px !important;
     letter-spacing: 0.1em;
     margin-right: 1.5rem;
     cursor: url('../icons/cursor-active.ico') 15 15, auto !important;
     text-align: center !important;
}

.linkset-one {
    margin-left: 2rem !important;
}

.linkset-two {
    right: 0;
    position: absolute;
}

.socials {
    right: -4%;
    position: absolute;
    width: 50%;
}

.social-a {
    cursor: url('../icons/cursor-passive.ico') 15 15, auto;
}

.socials-img {
    margin-right: 1rem;
    width: 3%;
    cursor: url('../icons/cursor-active.ico') 15 15, auto;
}

.connect-wallet {
    color:  #c9bba6 !important;
    -webkit-text-stroke: 1.5px black;
    font-size: 22px !important;
    font-family: conthrax;
    letter-spacing: 0.1em;
    margin-right: 1.5rem;
    cursor: url('../icons/cursor-active.ico') 15 15, auto;
}


.navbar-toggler {
    border: none !important;
    background: transparent !important;
    color: #faf2ff !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg%27%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  }

.nav-drop {
    color: #a753c6 !important;
}

.drop {
    background: #faf0fe;
    
}

.disabled {
    color: black !important;
}

.disabled:hover {
    cursor: url('../icons/disabled.ico') 15 15, auto;
}

.nav-links:hover {
    color: #f7bbf6 !important;

}

.connect-wallet:hover {
    color: #f7bbf6 !important;
    cursor: url('../icons/cursor-active.ico') 15 15, auto !important;

}

@media screen and (max-width: 1680px) {

    .linkset-one {
        margin-left: 8rem !important;
    }

    .socials {
        right: 10px;
    }

}

@media screen and (max-width: 1380px) {

    .linkset-one {
        margin-left: 8rem !important;
    }

    .nav-links {
         font-size: 12px !important;
    }

    .socials {
        right: 0;
        position: absolute;
        width: 55%;
    }

    .connect-wallet {
        font-size: 18px !important;
    }

    .socials-img {
        width: 4%;
    }

}

@media screen and (max-width: 1400px) {

    
}

@media screen and (max-width: 1080px) {
    .socials {
        right: 50px;
    }
    
}

@media screen and (max-width: 520px) {

    .linkset-one {
        margin-left: 0 !important;
    }

    .linkset-two {
        position: relative;
    }

    .socials {
        position: relative;
        width: 100%;
        right: 0;
    }


.socials-img {
    margin: 1rem 1.5rem 1rem 0;
    width: 8%;
    cursor: url('../icons/cursor-active.ico') 15 15, auto;
}

.connect-wallet {
    font-size: 20px !important;
}
}