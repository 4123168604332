 body {
    margin: 0;
    padding: 0;
}

.home-container {
    position: relative;
    cursor: url('../icons/cursor-passive.ico') 15 15, auto;
    z-index: 3;
    height: 100%;
    padding-bottom: 3rem;
    overflow-y: hidden;
}

.video-container {
    position:fixed;
    height: 100%;
    width: 100%;
    z-index: -11;
}

#video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 0 85% !important;
    z-index: -11;  
    
}

.whitelist-checker {
    position: fixed;
    left: 1%;
    bottom: 0;
    font-family: zekton;
    color: white;
    z-index: 100;
}

.whitelist-checker-img {
    width: 70%;
    cursor: url('../icons/cursor-active.ico') 15 15, auto;
}

.wl-tracker-text {
    text-align: left;
    font-size: 20px;
    padding-left: 2.5rem;
}


.header-images {
    margin: 12rem 0 0 0;
    position: relative;
}

.planet-title {
    position: absolute;
    left: 7%;
    top: -75px;
    width: 90%;
}

.degens {
    position: absolute;
    left: 16%;
    top: 11%;
    width: 75%;
}

.logo {
    position: absolute;
    left: 6%;
    top: -10px;
    width: 8%;
    z-index: 100;
}

.bg {
    position: absolute;
    left: -260px !important;
    top: -50px;
    width: 140%;
    z-index: -3;
    opacity: 0.8 !important;
}

.crystal {
    position: absolute;
    left: 44%;
    top: -125px;
    width: 17%;
    cursor: url('../icons/cursor-active.ico') 15 15, auto !important;
    z-index: 111 !important;
}


.planet {
    position: absolute;
    left: 100px;
    top: -95px;
    z-index: -1;
    width: 90%;
}

.mint-header {
    position: absolute;
    top: -150px;
    left: 500px;
}

.meteor-container {
    position: fixed;
    z-index: -4;
    width: 100%;
}

.home-button {
    background-color: #88449f !important;
    border-color: #d3d4d3 !important;
    position: absolute;
    left: 10px;
    font-family: zekton !important;
    font-size: 24px;
}



@media screen and (max-width: 1500px) {
    .whitelist-checker-img {
        width: 35% !important;
    }

    .wl-tracker-text {
        padding-left: 0.5rem;
    }

    .header-images {
        margin: 10rem 0 0 0;
        position: relative;
    }

    .mint-header {
        position: absolute;
        top: -150px;
        left: 400px;
    }

    
}


@media screen and (max-width: 1380px) {


        
    .planet-title {
        left: 16%;
        top: -75px;
        width: 70%;
    }
    
    .degens {
        left: 250px;
        top: -20px !important;
        width: 60%;
    }
    
    
    .bg {
        left: -30px !important;
        top: -50px !important;
        width: 105% !important;
        height: auto !important;
    }
    
    .crystal {
        left: 44%;
        top: -135px;
        width: 15%;
    }
    
    .planet {
        left: 160px;
        top: -95px;
        width: 75%;
    }

    .whitelist-checker-img {
        width: 35%;
    }

    .wl-tracker-text {

        font-size: 12px;
        padding-left: 1.5rem;
    }
    
}

@media screen and (max-width: 1080px) {

    .planet-title {
        left: 11%;
        top: -75px;
        width: 80%;
    }
    
    .degens {
        left: 16%;
        top: -20px !important;
        width: 65%;
    }
    
    
    .bg {
        left: -40px !important;
        top: -50px !important;
        width: 110% !important;
        height: auto !important;
    }
    
    .crystal {
        left: 44%;
        top: -125px;
        width: 15%;
    }
    
    .planet {
        left: 110px;
        top: -95px;
        width: 80%;
    }

    .header-images {
        margin: 15rem 0 0 0;
    }

    .mint-header {

        position: absolute;
        top: -150px;
        left: 300px;

    }



}

@media screen and (max-width: 570px) {

    .planet-title {
        position: absolute;
        left: 70px;
        top: -85px;
        width: 70%;
    }
    
    .degens {
        position: absolute;
        left: 13%;
        top: -70px !important;
        width: 75%;
    }
    
    .logo {
        position: absolute;
        right: 20px !important;
        left: auto;
        top: 0;
        width: 12%;
        z-index: 100;
    }
    
    .bg {
        position: absolute;
        left: -0px !important;
        top: -83px !important;
        width: 100% !important;
        height: 190px !important;
        z-index: -3;
    }
    
    .crystal {
        position: absolute;
        left: 185px;
        top: -120px;
        width: 17%;
    }
    
    .planet {
        position: absolute;
        left: 30px;
        top: -105px;
        z-index: -1;
        width: 90%;
    }

    .mint-header {
        position: absolute;
        top: -170px;
        left: 25px;
    }
    

}


@media screen and (max-width: 480px) {

    #video {
        display: none;

    }

    .home-container {
        background-image: url('../gif/background.gif');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-position-x: 70%;

    }


    .team-text2 {
        font-size: 10px !important;
    }


    .home-body {
        padding: 0 1rem;
    }

    .header-images {
        margin: 20rem 0 0 0;
    }

    .whitelist-checker-img {
        margin-left: 1rem;
    }

}


