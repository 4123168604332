.info-container {
    padding: 3rem;
    border-radius: 10px;
    font-family: Avalors;
    color: white;
    text-align: center;
    width: 100%;
    height: auto;
    display: inline-flex;
    margin-bottom: 10rem;
   
}

.info-section {
    background-color: rgb(250, 242, 255, 0.2); 
    border: 2px solid white;
    border-radius: 5px;
}

.overview-header {
    margin: 2rem 0; 
    font-size: 32px;
}

.bot-container {
    justify-content: left;

    position: relative;
}

.util-container {
    justify-content: left;
    position: relative;
}

.helmet-container {
    position: relative;
}

.info {
    margin-bottom: 3rem;
    font-size: 18px !important;
    font-family: zekton;
    letter-spacing: 0.1em;
    line-height: normal;
    padding: 0 10rem;
}


.utility {
    margin-bottom: 3rem;
    font-size: 18px !important;
    font-family: zekton;
    letter-spacing: 0.1em;
    line-height: normal;
    padding: 0 10rem;
    text-align: left;
}

.carousel {
    background-color: rgb(250, 242, 255, 0.2); 
    border: 2px solid white;
    border-radius: 5px;
    width: 50%;
}



.lore-container {
    padding: 1rem;
    border-radius: 10px;
    font-family: zekton;
    color: white;
    text-align: center;
    display: inline-flex;
    margin-bottom: 10rem;
}


.lore {
    margin-bottom: 1rem;
    font-size: 16px;
}

.origins-header {
    margin-top: 2rem;
    margin-bottom: 0; 
    font-family: Avalors; 
    font-size: 32px;
}

.rightArrow {
    position: absolute;
    bottom: 0%;
    right: 20px;
    font-size: 10rem;
    cursor: url('../icons/cursor-active.ico') 15 15, auto !important;
  }
  
  .leftArrow {
    position: absolute;
    bottom: 50%;
    left: 50%;
    font-size: 10rem !important;
    cursor: url('../icons/cursor-active.ico') 15 15, auto !important;
  }

.team-links {
    text-decoration: none;
    color: white;
    cursor: url('../icons/cursor-active.ico') 15 15, auto !important;
}

.team-links:hover {
    text-decoration: none;
    color: white;
    cursor: url('../icons/cursor-active.ico') 15 15, auto !important;
}

.team-container {
    color: white;
    font-family: Avalors;
    text-align: center;
    padding: 2rem;
    background-color: rgb(250, 242, 255, 0.2);
    border: 2px solid white;
    border-radius: 5px;
}


.team-row {
    display: flex;
    flex-direction: row;
    text-align: center !important;
}

.team-row2 {
    display: flex;
    flex-direction: row;
    text-align: center !important;
    justify-content: space-around !important;
}

.team-widget {
    max-width: 50% !important;
    padding: 0;
}

.mobile {
    display: none;
}


.team-image {
    width: 33%;
    border-radius: 5px;
}

.team-image2 {
    width: 33%;
    border-radius: 5px;
}


.planet-gif {
    margin: 0 0.5rem;
}


.lore-title {
    font-family: Avalors;
}

.lore-text {
    color: white;
    font-family: zekton;
    letter-spacing: 0.1em;
    font-size: 18px;
   
}

.mobile-spacing {
    min-height: 60rem;
}

.mobile-team-row {
    display: flex;
    flex-direction: row;
}

.team-header {
    font-size: 32px;
    margin-bottom: 2rem; 
}

.faq-header {
    color: #FFFFFF;
    font-family: Avalors;
    text-align: center;
    padding-top: 2rem; 
    font-size: 32px;
}

.helmet {
    position: absolute;
    width: 350px;
    left: 300px;
    top: 50px;
}

.bot {

    width: 350px;
}

.util {
    margin-top: 5rem;
    width: 350px;
}

.mint-counter {
    border: none !important;
    background: transparent !important;
}


.mint-counters {
    justify-content: center;
    width: 100%;
}


.mint-container {
    height: 100vh;
    justify-content: center;
    width: 100%;
    background-position-y: -70px !important;
    background-position-x: -5px !important;
    font-family: zekton;
    color: white;
    font-weight: bold; 
    font-size: 24px;
    overflow-x: hidden;
    background-repeat: no-repeat;
    background-size: cover;
}

.mint-popup {
    text-align: center;
    font-family: zekton;
    color: white;
    font-weight: bold; 
    font-size: 32px;
    justify-content: center;
    width: 100%;
}

.mint-buttons {
    position: absolute;
    bottom: 0;
    width: 100vw;

}

.mint-button {
    width: 45%;
}

.plus-minus {
    width: 60%;
}

.counter {
    background: white;
    border: 2px solid black;
    color: black;
    text-align: center;
    width: 5%;
}

.mint-connect-btn {
    position: absolute !important;
    right: 10px !important;
    color:  white !important;
    font-size: 16px !important;
    letter-spacing: 0.1em;
    cursor: url('../icons/cursor-active.ico') 15 15, auto;
    border: 2px solid #88449f;
    border-radius: 5px;
    padding: 0.5rem !important;
    background-color: #88449f !important;
    border-color: #d3d4d3 !important;
    font-family: zekton !important;
}


@font-face {
    font-family: Avalors;
    src: url('../fonts/Avalors.otf');
}

@font-face {
    font-family: zekton;
    src: url('../fonts/zekton.otf');
}


@font-face {
    font-family: conthrax;
    src: url('../fonts/conthrax.otf');
}


@media screen and (max-width: 2560px) {

    .info {
        font-size: larger;
    }

    .info-container {
        padding: 2rem;
    }
     
    
}


@media screen and (max-width: 2150px) {

     
    
}

@media screen and (max-width: 1890px) {
 
    
}

@media screen and (max-width: 1500px) {

    .info-container {
        padding: 2rem;
        margin-bottom: 10rem;
       
    }

    .info {
        margin-bottom: 3rem;
        font-size: 14px !important;
        padding: 0 3rem;
    }
    
    .team-container {
        width: 80% !important;
    }

    .overview-header {
        margin: 2rem 0; 
        font-size: 24px;
    }

    .origins-header {
        font-size: 24px;
    }

    .lore-text {
        font-size: 14px;  
    }

    .helmet {
        left: 150px;
        top: 10px;
    }

    .bot {
        margin-right: 6rem;
    }

    .mobile-spacing {
        min-height: 35rem;
    }

    .team-image {
        width: 40%;
        border-radius: 5px;
    }

    .utility {
        margin-bottom: 3rem;
        font-size: 14px !important;
        padding: 0 3rem;
    }

    .mint-container {
        font-size: 18px;
        background-position-y: -60px !important;
        background-position-x: 0 !important;
    }

    .mint-button {
        width: 30%;
    }

 
    
}


@media screen and (max-width: 1080px) {


    .mint-container {
        background-position-y: -30px !important;
        background-position-x: -670px !important;
        font-size: 24px;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        
    }

    .mint-buttons {
        bottom: 30px;
    
    }

    .counter {
        margin-top: 2rem;
    }

     
    
}


@media screen and (max-width: 950px) {

    .info-container {
        padding: 2rem;
    }

    .whitelist-checker {
        left: 14%;
        bottom: 14%;
    }

    
     
    
}





@media screen and (max-width: 510px) {


    .bot {
        position: absolute;
        left: -70px;
        top: 200px;
        width: 100px;
    }

    .info {
        margin-bottom: 3rem;
        font-size: 12px !important;
        padding: 0 3rem;
    }
    
    .team-container {
        width: 80% !important;
    }

    .overview-header {
        margin: 2rem 0; 
        font-size: 16px;
    }

    .origins-header {
        font-size: 16px;
    }

    .lore-text {
        font-size: 12px !important;  
    }

    .info-container {
        padding: 0.5rem;
       
    }

    .info-section {
        padding: 0;
        width: 90%;
        position: absolute;
        right: -60px;
    }

    .team-image {
        width: 50%;
        border-radius: 5px;
    }

    .team-container {
        width: 100% !important;
    }

    .helmet {
        width: 90px;
        left: 80px;
        top: 200px;
    }
     
    .lore-container {
        margin-top: 30rem;
    }
    
    .mint-container {
        background-position-y: -30px !important;
        background-position-x: -510px !important;
        font-size: 24px;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        
    }

    .counter {
        width: 30%;
        margin-top: 1rem;
    }

    .mint-button {
        width: 45%;
        
    }

    .mint-buttons {
        bottom: 0;
    
    }


.mint-connect-btn {
    position: absolute !important;
    right: 10px !important;
    color:  white !important;
    font-size: 12px !important;
    width: 25%;
    text-align: center;
    letter-spacing: 0.1em;
    cursor: url('../icons/cursor-active.ico') 15 15, auto;
    border: 2px solid #88449f;
    border-radius: 5px;
    padding: 0.5rem !important;
    background-color: #88449f !important;
    border-color: #d3d4d3 !important;
    font-family: zekton !important;
}
    
}



@media screen and (max-width: 450px) {

    .info-container {
        margin-top: 20rem;
    }
    

    .mobile {
        display: contents;
    }

    .non-mobile {
        display: none;
    }
    

    .team-image {
        width: 70%;
    }

    .mobile-spacing {
        min-height: 10rem !important;
    }

    .whitelist-checker {
        left: 5px;
        bottom: 5px;
        cursor: url('../icons/cursor-active.ico') 15 15, auto;
        width: 45%;
    }

    .info-section {
        right: 20px;
        overflow-y: hidden;
        z-index: -1;
    }
    

    .bot {
        position: absolute;
        width: 150px;
        top: -115px;
        left: 0;
        z-index: 10;
    }

    .helmet {
        width: 140px;
        left: -115px;
        top: -90px;
    }

    .util {
        position: absolute;
        top: -170px;
        left: -30px;
        width: 150px;
    }
    
    .carousel {
        width: 100%;
    }

    .lore-container {
        margin-bottom: 0;
    }
    
    .team-container {
        margin-top: 40rem;
    }

    .team-header {
        font-size: 18px;
        margin-bottom: 2rem; 
    }

    .faq-header {
        font-size: 24px;
    }

    .lore-container {
        margin-bottom: 0;
    }
    
    
    
    
}



@media screen and (max-height: 700px) and (max-width: 480px) {

    .info-container {
        margin-top: 20rem;
    }
    

    .mobile {
        display: contents;
    }

    .non-mobile {
        display: none;
    }
    

    .team-image {
        width: 70%;
    }

    .mobile-spacing {
        min-height: 10rem !important;
    }

    .whitelist-checker {
        left: 5px;
        bottom: 5px;
        cursor: url('../icons/cursor-active.ico') 15 15, auto;
        width: 45%;
    }

    .info-section {
        right: 20px;
        overflow-y: hidden;
        z-index: -1;
    }
    

    .bot {
        position: absolute;
        width: 150px;
        top: -115px;
        left: 0;
        z-index: 10;
    }

    .helmet {
        width: 140px;
        left: -115px;
        top: -90px;
    }

    .util {
        position: absolute;
        top: -170px;
        left: -30px;
        width: 150px;
    }
    
    .carousel {
        width: 100%;
    }

    .lore-container {
        margin-bottom: 0;
    }
    
    .team-container {
        margin-top: 40rem;
    }

    .team-header {
        font-size: 18px;
        margin-bottom: 2rem; 
    }

    .faq-header {
        font-size: 24px;
    }

    .lore-container {
        margin-bottom: 0;
    }
    
    .mint-container {
        background-position-y: 0 !important;
        background-position-x: -355px !important;
        font-size: 24px;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        
    }

    .counter {
        width: 30%;
        margin-top: 1rem;
    }

    .mint-button {
        width: 45%;
        
    }

    .mint-buttons {
        bottom: 0;
    
    }
    
    
}

