

.faq {
    height: 100%;
    padding-bottom: 2rem;
    font-family: Avalors;
    font-size: 16px !important;
    background-color: transparent;
    background-position-y: -30rem !important;
    cursor: url('../icons/cursor-passive.ico') 15 15, auto;
    z-index: 15;
    margin-top: 3rem;
}


.accordion-button {
    background-color: rgb(250, 242, 255, 0) !important;
    border: 1px solid white !important;
    cursor: url('../icons/cursor-passive.ico') 15 15, auto;
    color: white !important;
    font-size: 16px !important;
    
  }

  .accordion-button:hover {
    cursor: url('../icons/cursor-active.ico') 15 15, auto;
    
  }

  .accordion-header {
    font-size: 16px !important;
  }

  .cursor {
    cursor: url('../icons/cursor-active.ico') 15 15, auto;
  }

.accordion-button:not(.collapsed) {
    color: #212529;
    background-color: rgb(250, 242, 255, 0) !important;
  }




  .accordion-button::after {
	/* background-image: url("../images/ship.png") !important; */
  color: black !important;
}



  @media screen and (max-width: 1050px ) {
    .accordion {
        margin: 6rem 0 1rem 0 !important;
    }

    
}

@media screen and (max-width: 760px ) {
    .col-7 {
        padding: 2rem 2rem !important;
        margin-top: 0;
        width: 100vw !important;
    }

    
    .accordion {
        margin-top: 2rem !important;
      }
    
    
}
